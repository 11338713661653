import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { makeUrlWithLang } from '../../utils/helpers';
import config from '../../config';

import BinaModal from '../ReactModal';

const AuthModal = ({ isOpen, closeHandler, modalId }) => {
  const {
    t,
    i18n: { language: lang }
  } = useTranslation();
  const agreement = t('authentication.agreement');
  const rules = t('authentication.rules');
  const { protocol, host } = config;
  const hostString = path => makeUrlWithLang({ protocol, host, path, lang });

  return (
    <BinaModal modalId={modalId} isOpen={isOpen} contentClassName="auth-modal">
      <div className="auth-container">
        <div className="auth">
          <div
            className="auth__close-btn"
            onClick={closeHandler}
            onKeyPress={closeHandler}
            role="button"
            tabIndex={0}
          />
          <div>
            <div className="auth__logo" />
            <div className="auth__promo">{t('authentication.promo')}</div>
            <a
              href={hostString('/authentications/new')}
              className="auth__btn auth__btn--default"
              onClick={closeHandler}
            >
              {t('authentication.auth_by_phone')}
            </a>
            <a
              className="auth__btn auth__btn--email"
              href={hostString('/company_session/new')}
            >
              {t('authentication.auth_by_email')}
            </a>
          </div>
          <div className="auth__connector-container">
            <span className="auth__connector">{t('authentication.or')}</span>
          </div>
          <a
            className="auth__link auth__link--bottom"
            href={hostString('/companies/new')}
          >
            {t('authentication.registration')}
          </a>
          <div className="auth__confirmation">
            <Trans
              i18nKey="authentication.confirmation"
              values={{
                agreement,
                rules
              }}
            >
              <a href="/pages/terms-and-conditions">{{ agreement }}</a>
              <a href="/pages/rules">{{ rules }}</a>
            </Trans>
          </div>
        </div>
      </div>
    </BinaModal>
  );
};

AuthModal.propTypes = {
  children: PropTypes.func || PropTypes.node,
  closeHandler: PropTypes.func,
  isOpen: PropTypes.bool,
  modalId: PropTypes.string
};

export default memo(AuthModal);
