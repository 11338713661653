import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noScroll from 'no-scroll';

const Drawer = memo(
  ({ toggleDrawer, isVisible, additionalClass, children }) => {
    const drawerClassName = cn(
      'drawer',
      {
        'drawer--opened': isVisible
      },
      additionalClass
    );

    useEffect(() => {
      if (isVisible) {
        noScroll.on();
      } else {
        noScroll.off();
      }

      return () => {
        noScroll.off();
      };
    }, [isVisible]);

    return (
      <div className={drawerClassName}>
        <div
          className="drawer__overlay"
          onClick={toggleDrawer}
          onKeyPress={toggleDrawer}
          role="link"
          tabIndex="0"
          data-cy="close-drawer"
        />
        <div className="drawer__content">{children}</div>
      </div>
    );
  }
);

Drawer.displayName = 'Drawer';

Drawer.propTypes = {
  toggleDrawer: PropTypes.func,
  isVisible: PropTypes.bool,
  additionalClass: PropTypes.string,
  children: PropTypes.node
};

export default Drawer;
