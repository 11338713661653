const adriverExtentionLoad = (a, b, containerId) => {
  const container = document.getElementById(containerId);
  if (a === 0 && container) {
    container.style.height = '0';
    container.style.display = 'none';
  } else {
    container.style.height = '';
    container.style.display = '';
  }
};

export default adriverExtentionLoad;
