import { gql } from '@apollo/client';

export const companyNameQuery = gql`
  query CompanyNameQuery {
    profile {
      ... on CompanyProfile {
        name
        id
        phone
      }
    }
  }
`;

const temp = 'just for eslint';

export default temp;
