import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bugsnag from '../../utils/bugsnag';
import ErrorIcon from '../../public/static/images/search/icons/icon-error.svg';
import FallbackView from '../FallbackView';
import { setError } from '../../actions';

const mapStateToProps = state => {
  return {
    isError: state.errorUI.isError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static propTypes = {
    t: PropTypes.func,
    children: PropTypes.node,
    i18n: PropTypes.object,
    router: PropTypes.object,
    statusCode: PropTypes.number,
    isError: PropTypes.bool,
    setPageLoader: PropTypes.func,
    setErrorUI: PropTypes.func
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });

    Bugsnag.notify(error);
    console.log(`Error: ${error}`, errorInfo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.statusCode !== 500 && prevProps.isError) {
      this.props.setErrorUI(false);
    }
  }

  render() {
    const { i18n, router, isError, statusCode } = this.props;
    const { error } = this.state;

    if (error || isError || statusCode === 500) {
      return (
        <FallbackView
          Icon={ErrorIcon}
          width="102px"
          height="63px"
          text={i18n.t('search.error.text')}
          title={i18n.t('search.error.title')}
          btnText={i18n.t('search.error.link')}
          onClickHandler={() => router.reload()}
        />
      );
    }

    return this.props.children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
