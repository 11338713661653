import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';
import initialState from '../store/initialState';

export const searchForm = handleActions(
  {
    [actions.switchTab](state, { payload }) {
      return { ...state, currentTabId: payload };
    },
    [actions.setSearchSort](state, { payload }) {
      return { ...state, currentSortType: payload };
    },
    [actions.showPopupHeader](state) {
      return { ...state, 'popup-header': true };
    },
    [actions.hidePopupHeader](state) {
      return { ...state, 'popup-header': false };
    },
    [actions.setCurrentCity](state, { payload }) {
      return { ...state, currentCity: payload };
    },
    [actions.setCurrentCityIsDeferred](state, { payload }) {
      return { ...state, currentCityIsDeferred: payload };
    },
    [actions.setSearchValues](state, { payload }) {
      return { ...state, values: payload };
    },
    [actions.setResetPopupHandler](state, { payload }) {
      return { ...state, resetPopupHandler: payload };
    },
    [actions.setLocationsResetButtonIsDisabled](state, { payload }) {
      return { ...state, locationsResetButtonIsDisabled: payload };
    },
    [actions.setResetButtonIsDisabled](state, { payload }) {
      return { ...state, resetButtonIsDisabled: payload };
    },
    [actions.setChosenLocationNames](state, { payload }) {
      return { ...state, chosenLocationNames: payload };
    },
    [actions.setCurrentState](state, { payload }) {
      return { ...state, currentState: payload };
    }
  },
  initialState.searchForm
);

export const authModal = handleActions(
  {
    [actions.toggleAuthModal](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.authModal
);

export const drawerUI = handleActions(
  {
    [actions.toggleDrawer](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.drawerUI
);

const errorUI = handleActions(
  {
    [actions.setError](state, { payload }) {
      return { ...state, isError: payload };
    }
  },
  initialState.errorUI
);

const blockerDetector = handleActions(
  {
    [actions.setBlockerDetector](state, { payload }) {
      return { ...state, isDetected: payload };
    }
  },
  initialState.blockerDetector
);

export default combineReducers({
  authModal,
  drawerUI,
  searchForm,
  errorUI,
  blockerDetector
});
